<template>
  <container class="p-1">
    <dx-grid-with-search
      :toolbar="toolbar"
      gridName="orderMaintenance"
      :dataSource="procedures"
      :columns="columns"
      title="Order Maintenance"
      @initialized="initializeGrid"
    >
      <template v-slot:extraActions>
        <add-button @click="editProcedure" />
      </template>
      <template v-slot:actions="{ data }">
        <icon-button
          class="text-primary pointer p-0"
          icon="pen-alt"
          v-tooltip.left="'Edit procedure'"
          @click="editProcedure(data)"
        />
      </template>
      <template v-slot:expirationCell="{ data }">
        <div class="align-items-center">
          <ExpiryTemplate :expiry-date="data.value" />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isOpen" @close="handleClose">
      <procedure :procedureId="procedureId" @submit="handleSubmit" @close="handleClose" />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "../../common/DxGridWithSearch.vue";
import { mapGetters, mapState } from "vuex";
import api from "../../../services/api";
import DataSource from "devextreme/data/data_source";
import AddButton from "../../common/AddButton.vue";
import { createLogComment, fromLetters, toLetters } from "../../../modules/helpers";
import auditLog from "../../../services/AuditLog";
import { createLogItem } from "../../../modules/helpers";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import Container from "../../common/Container.vue";
import Modal from "@/components/common/Modal.vue";
import Procedure from "./Procedure.vue";
import { DropdownApi, ProceduresApi } from "@/services";
import IconButton from "@/components/common/IconButton.vue";
import { SpecimenNumbersEnum } from "@/modules/enums";
import { handleMissingCptPairs } from "@/modules/handleMissingCptPairs";
export default {
  name: "OrderMaintenance",
  metaInfo: {
    title: "Order Maintenance",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    DxGridWithSearch,
    AddButton,
    ExpiryTemplate,
    Container,
    Modal,
    Procedure,
    IconButton
  },
  data() {
    return {
      grid: {},
      isOpen: false,
      procedureId: null,
      originalProcedure: {},
      diagnosisOptions: []
    };
  },
  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin Orders page."
    });
    DropdownApi.getOrderDiagnosis().then(res => {
      this.diagnosisOptions = res || [];
    });
  },
  computed: {
    ...mapState(["currentLab", "labSettings"]),
    ...mapGetters("accessionStore", ["specimenNumbering"]),
    procedures() {
      return new DataSource({
        store: api.createSearch(`/api/Labs/${this.currentLab}/Procedures`),
        key: "id"
      });
    },
    columns() {
      return [
        {
          dataField: "description",
          sortIndex: 0,
          allowSorting: true,
          type: "string"
        },
        {
          dataField: "code",
          dataType: "string"
        },
        {
          caption: "Block ",
          dataField: "defaultBlockNum",
          calculateDisplayValue: data => {
            if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
              return data.defaultBlockNum ? toLetters(data.defaultBlockNum) : "";
            }
            return data.defaultBlockNum;
          }
        },
        {
          caption: "Slides",
          dataField: "numberOfSlides",
          dataType: "number",
          width: "10%"
        },
        {
          caption: "Hold Code",
          dataField: "holdCodeDisplayName"
        },
        {
          caption: "Transaction Code",
          dataField: "billingTransactionCodeDisplayName"
        },
        { dataField: "expiryOn", dataType: "date", cellTemplate: "expirationCell" },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            template: "extraActions",
            locateInMenu: "auto"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    }
  },
  methods: {
    initializeGrid(event) {
      this.grid = event.component;
    },
    handleClose() {
      this.isOpen = false;
    },
    editProcedure(data) {
      this.isOpen = true;
      if (!data) {
        this.procedureId = null;
      }
      this.procedureId = data.key;
    },
    async handleSubmit(procedure) {
      if (isNaN(procedure.defaultBlockNum)) {
        procedure.defaultBlockNum = fromLetters(procedure.defaultBlockNum);
      }
      let response;
      if (procedure.id) {
        const originalProcedure = await ProceduresApi.getProcedure(procedure.id);
        response = await ProceduresApi.updateProcedure(procedure);
        handleMissingCptPairs(response);
        const logItem = createLogItem({}, 4);
        logItem.comments = createLogComment(
          {
            ...originalProcedure,
            orderDiagnosisIds: originalProcedure.orderDiagnosisIds.map(id => {
              const item = this.diagnosisOptions.find(e => e.id === id);

              return item;
            })
          },
          {
            ...procedure,
            orderDiagnosisIds: procedure.orderDiagnosisIds.map(id => {
              const item = this.diagnosisOptions.find(e => e.id === id);

              return item;
            })
          }
        );
        logItem.comments = `${originalProcedure.code}: ${logItem.comments}`;
        auditLog.insertLogMessage(logItem);
      } else if (!procedure.id) {
        response = await ProceduresApi.createProcedure(procedure);
        handleMissingCptPairs(procedure);
        const logItem = createLogItem({}, 5);
        logItem.comments = `Created ${procedure.code} - ${procedure.description}`;
        auditLog.insertLogMessage(logItem);
      }
      if (response?.validationErrors?.length) {
        window.alert(response.validationErrors.join("\n"));
        return;
      }

      this.isOpen = false;
      this.procedures.reload(true);
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
